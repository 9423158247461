<template>
  <b-modal
    :visible="isModalActive"
    :title="$t('Change Password')"
    ok-title="Accept"
    @change="(val) => $emit('update:is-modal-active', val)"
  >
    <validation-observer ref="formData">
      <b-form class="p-2">
        <b-row>
          <b-col cols="12">
            <!-- New Password -->
            <b-form-group
              :label="$t('New Password')"
              label-for="register-new-password"
            >
              <validation-provider
                #default="{ errors }"
                name="password"
                vid="password"
                rules="required|min:6"
              >
                <b-form-input
                  id="register-new-password"
                  ref="password"
                  v-model="password"
                  name="register-new-password"
                  :state="errors.length > 0 ? false:null"
                  :placeholder="$t('New Password')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <!-- New Password -->
            <b-form-group
              :label="$t('Confirm Password')"
              label-for="register-confirm-password"
            >
              <validation-provider
                #default="{ errors }"
                name="confirm"
                vid="confirmPassword"
                rules="confirmed:password"
              >
                <b-form-input
                  id="register-confirm-password"
                  v-model="confirmPassword"
                  name="register-confirm-password"
                  :state="errors.length > 0 ? false:null"
                  :placeholder="$t('Confirm Password')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>

    <template #modal-footer>
      <div class="w-100">
        <p class="float-left mb-0" />
        <div
          class="float-right"
        >
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="success"
            size="sm"
            class="mr-2"
            @click="changePassword()"
          >
            {{ $t('Save') }}
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            size="sm"
            class=""
            @click="$emit('update:is-modal-active', false)"
          >
            {{ $t('Close') }}
          </b-button>
        </div>

      </div>
    </template>
  </b-modal>
</template>

<script>

import {
  BModal,
  BRow,
  BCol,
  // BTabs,
  // BTab,
  BCardText,
  BFormCheckbox,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  // BOverlay,

} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import store from '@/store'

extend('password', {
  params: ['target'],
  validate(value, { target }) {
    return value === target
  },
  message: 'Password confirmation does not match',
})

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
    BModal,
    // eslint-disable-next-line vue/no-unused-components
    BRow,
    // eslint-disable-next-line vue/no-unused-components
    BCol,
    // BTabs,
    // BTab,
    // eslint-disable-next-line vue/no-unused-components
    BCardText,
    // eslint-disable-next-line vue/no-unused-components
    BFormCheckbox,
    // eslint-disable-next-line vue/no-unused-components
    BButton,
    // BOverlay,
    ValidationProvider,
    ValidationObserver,
    BForm,
    BFormGroup,
    BFormInput,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isModalActive',
    event: 'update:is-modal-active',
  },
  props: {
    storeModuleName: {
      type: String,
      required: true,
    },
    isModalActive: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      default: () => {},
    },
    show: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      dataId: null,
      dataLoading: false,
      password: '',
      confirmPassword: '',
      required,
    }
  },
  computed: {
  },
  watch: {
    isModalActive(val) {
      console.log(this.data)
      if (val) {
        if (Object.entries(this.data).length === 0) {
          this.$refs.formData.reset()
          this.initValues()
        } else {
          const {
            partnerUserId,
          } = this.data
          this.dataId = partnerUserId._id
        }
      }
    },
  },
  methods: {
    initValues() {
      this.dataId = null
      this.password = ''
      this.confirmPassword = ''
    },
    changePassword() {
      this.$refs.formData.validate().then(success => {
        if (success) {
          this.$bvModal
            .msgBoxConfirm(`${this.$t('Do you want to change')}?`, {
              title: this.$t('Please Confirm'),
              cancelVariant: 'outline-secondary',
              okVariant: 'success',
              okTitle: this.$t('Yes'),
              cancelTitle: this.$t('No'),
              centered: true,
            })
          // eslint-disable-next-line no-unused-vars
            .then(value => {
              if (value) {
                this.dataLoading = true
                const obj = {
                  _id: this.dataId,
                  password: this.password,
                }
                store
                  .dispatch(`${this.storeModuleName}/changePassword`, obj)
                // eslint-disable-next-line no-unused-vars
                  .then(result => {
                    this.initValues()
                    this.dataLoading = false
                    this.$emit('update:is-modal-active', false)
                    this.$emit('update:show', false)
                  }).catch(error => {
                    this.dataLoading = false
                    this.$toast({
                      component: ToastificationContent,
                      position: 'bottom-right',
                      props: {
                        title: this.$t('Error'),
                        icon: 'ErrorIcon',
                        variant: 'danger',
                        text: this.$t(error.response.data.message),
                      },
                    })
                  })
              }
            })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
